// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require.context('../images', true)

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("chartkick")
require("chart.js")

import "bootstrap"

import Rails from "@rails/ujs";
window.Rails = Rails;

// import stylesheets
import "../styles/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//flatpickr
// import flatpickr from "flatpickr"
// require("flatpickr/dist/flatpickr.css");

// document.addEventListener("turbolinks:load", () => {
//   flatpickr("[data-behavior='flatpickr']", {
//     altInput: true,
//     altFormat: "d-m-Y",
//     dateFormat: "Y-m-d H:i",
//     defaultDate: new Date(Date.now()), // defaults to half hour in the future
//     maxTime: "20:00",
    
//     // maxDate: new Date(new Date().setHours(20,0,0,0) + (24 * 60 * 60 * 1000)), // set to 8pm tomorrow
//   });
// })























