import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {

  // room-id in navbar in app layout with profile icon 
  // this sets the room_id to the current business id
  const element = document.getElementById('room-id');
  const room_id = element.getAttribute('data-room-id');

  consumer.subscriptions.create({ channel: "OnlineChannel", room_id: room_id}, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("connected to online channel " + room_id);
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      // $('#room-id').prepend(

      //   '<li data-cart-id='+ data.subject +'>' + data.message + '</li>'
      // );

      $('#message-block').html(
         data.message
      ).delay(10000);
    }
  });

})
